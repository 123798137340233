export const LANDINGPAGE = {
	trueIdentity: {
		description: `
      The world we live in compels us to conform to societal
      notions. We inevitably subject ourselves to judgments
      and prejudices. Take charge of your own identity. <br /> <br /> Shed
      your veil and allow your true self to shine through in
      <b>PLAYGROUND</b>.
    `,
	},
	trueOwnership: {
		description: `
      Create, Personalize and Trade lands and assets that express
      your true individuality in our virtual realm. All assets
      hold tangible value and can be traded freely on
      decentralized open markets. <br /> <br />Through NFT empowerment, your
      assets are uniquely yours - within and outside of
      <b>PLAYGROUND</b>; now and forever
    `,
	},
  playAndEarn: {
    description: `
      Play and Earn. Prior to Web 3.0, playing is spending. With
      <b>PLAYGROUND</b>'s MMRPV concept, participate in a plethora of
      daily activities, quests, competitive expeditions and
      matches to gain rewards that carry real world value.
    `
  }
} as const;
