import React from 'react';
import Coins from '@images/landing-page/coins.png';
import './play-and-earn-section.scss';
import { AnimatedHeader } from '../animated-header';
import { useGlobalSetting } from '@/contexts/GlobalSettingContext';

const PlayAndEarnSection: React.FC = () => {
	const { isSmallerScreen } = useGlobalSetting();
	return (
		<section className="p2e-section" aria-labelledby="p2e-section-title">
			<img
				className="p2e-section__decorative-image"
				src={Coins}
				alt="coins"
				aria-hidden="true"
			/>
			<div className="p2e-section__main-content">
				<AnimatedHeader partial>
					<h2 id="p2e-section-title" className="p2e-section__title">
						<span className="p2e-section__title__main atmospheric-text">
							Play
						</span>
						<span className="p2e-section__title__sub atmospheric-text">
							And Earn
						</span>
					</h2>
				</AnimatedHeader>
				<h3 className="p2e-section__subtitle atmospheric-text">
					True P2E
				</h3>
				<p className="p2e-section__description corbel-text">
					Play and Earn. Prior to Web 3.0, {isSmallerScreen && <br />}
					playing is spending.
					<br /><br />
					With<b> PLAYGROUND</b>’s MMRPV concept, participate in a
					plethora of daily activities, quests, competitive
					expeditions and matches to gain rewards that carry real
					world value.
				</p>
			</div>
			{/* <img className="p2e-section__decorative-main-image" src={AvatarPhonePlayingImage} alt="stage" aria-hidden="true" /> */}
		</section>
	);
};

export { PlayAndEarnSection };
