import { PlayAndEarnSection } from '@/components/play-and-earn-section';
import { LandingPageIntroduction } from '@/components/landing-page-introduction';
import { RoadMapSection } from '@/components/road-map-section';
import { TrueEngagementSection } from '@/components/true-engagement-section';
import { TrueIdentitySection } from '@/components/true-identity-section';
import { TrueImmersionSection } from '@/components/true-immersion-section';
import { TrueOwnershipSection } from '@/components/true-ownership-section';
import { DefaultLayout } from '@/layouts/default-layout/DefaultLayout';
import '../styles/global.scss';

export default function Home() {
  return (
    <DefaultLayout>
      <LandingPageIntroduction />
      <TrueIdentitySection />
      <TrueOwnershipSection />
      <TrueEngagementSection />
      <TrueImmersionSection />
      <PlayAndEarnSection />
      {/* <RoadMapSection /> */}
    </DefaultLayout>
  );
}
