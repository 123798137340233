import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './true-engagement-section.scss';
import { AnimatedHeader } from '../animated-header';
import { SocialMediaButton } from '../social-media-button';
import { POPULAR_SOCIALMEDIALIST } from '@/constants/social-media';

const TrueEngagementSection: React.FC = () => {
	return (
		<section
			className="engagement-section"
			aria-labelledby="engagement-section-title"
		>
			<AnimatedHeader>
				<h2
					id="engagement-section-title"
					className="engagement-section__title atmospheric-text"
				>
					True engagement
				</h2>
			</AnimatedHeader>
			<h3 className="engagement-section__subtitle atmospheric-text">
				Join discord and participate
			</h3>
			<div className="engagement-section__description corbel-text">
				Discontented with being just a bystander? <b>PLAYGROUND</b>’s
				MMRPV recognizes the power of the autonomous community and
				believes that everyone deserves a voice. In our realm, you have
				a say. Engage with our team and the countless other MMRPV
				dwellers to participate in our growth and development. See you
				on our socials:{' '}
				<div>
					{Object.keys(POPULAR_SOCIALMEDIALIST).map(
						(item: string) => (
							<SocialMediaButton
								key={item}
								socialMedia={item as SocialMediaName}
								url={
									POPULAR_SOCIALMEDIALIST[
										item as SocialMediaName
									] as string
								}
							/>
						)
					)}
				</div>
			</div>
			<div
				className="engagement-section__image-wrapper"
				aria-hidden="true"
			>
				<div>
					<div className="engagement-section__image--diagonal-wrapper">
						<div className="engagement-section__image--diagonal">
							<StaticImage
								className="image-wrapper"
								aria-hidden="true"
								src="../../assets/images/landing-page/garden.jpeg"
								loading="eager"
								placeholder="blurred"
								alt="garden"
							/>
						</div>
					</div>
				</div>
				<div>
					<div className="engagement-section__image--diagonal-wrapper">
						<div className="engagement-section__image--diagonal">
							<StaticImage
								aria-hidden="true"
								className="image-wrapper"
								src="../../assets/images/landing-page/mma-arena.png"
								loading="eager"
								placeholder="blurred"
								alt="arena"
							/>
						</div>
					</div>
				</div>
				<div>
					<div className="engagement-section__image--diagonal-wrapper">
						<div className="engagement-section__image--diagonal">
							<StaticImage
								aria-hidden="true"
								className="image-wrapper"
								src="../../assets/images/landing-page/assassin-concept.png"
								loading="eager"
								placeholder="blurred"
								alt="assassin"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export { TrueEngagementSection };
