import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import { useGlobalSetting } from '@/contexts/GlobalSettingContext';
import './landing-page-introduction.scss';
import { configs } from '@/constants/configs';

// TODO: to reenable video and remove image placeholder

const LandingPageIntroduction: React.FC = () => {
	const { isSmallerScreen } = useGlobalSetting();
	const videoRef: React.RefObject<HTMLVideoElement> =
		React.useRef<HTMLVideoElement>(null);
	const [isVideoPlaying, setIsVideoPlaying] = React.useState<boolean>(false);

	const onButtonClick = () => {
		setIsVideoPlaying(!isVideoPlaying);
	};

	React.useEffect(() => {
		if (videoRef.current && isVideoPlaying) {
			videoRef.current.play();
		} else if (videoRef.current) {
			videoRef.current.pause();
		}
	}, [isVideoPlaying]);

	return (
		<section
			className="landing-intro-section"
			// aria-labelledby="intro-section-title"
		>
			{/* <h2 id="intro-section-title" className="corbel-text">
				<span className="corbel-bold-text">BTS Video</span>
				What's happening in PLAYGROUND?
			</h2> */}
			{/* <button
				className={clsx('landing-intro-section__btn', {
					'landing-intro-section__btn--play': !isVideoPlaying,
					'landing-intro-section__btn--pause': isVideoPlaying,
				})}
				onClick={onButtonClick}
			/> */}
			{/* <video
				width="100%"
				height="auto"
				ref={videoRef}
				loop
				webkit-playsinline=""
				playsInline
			>
				<source src={configs.introVideoLink} type="video/mp4" />
				Your browser does not support the video tag.
			</video> */}

			<StaticImage
				aria-hidden="true"
				className="image-placeholder"
				src={'../../assets/images/landing-page/metaverse-night.jpeg'}
				loading="eager"
				placeholder="blurred"
				alt="metaverse night"
			/>
		</section>
	);
};

export { LandingPageIntroduction };
