import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export interface ImageSliderProps {
	images: string[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({
	images,
}: ImageSliderProps) => {
	return (
		<Swiper
			cssMode
			navigation
			pagination
			mousewheel
			keyboard
			modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
			autoplay={{
				delay: 2000,
				disableOnInteraction: false,
			}}
			className="ownership-section__slider"
			aria-hidden="true"
		>
			{images.map((item: string, index: number) => (
				<SwiperSlide key={index}>
					<img src={item} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export { ImageSlider };
