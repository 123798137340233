import React from 'react';
import SkyTowerImage from '@images/landing-page/sky-tower-concept.png';
import Layout1x1Image from '@images/landing-page/land-1x1.png';
import Layout2x2Image from '@images/landing-page/land-2x2.png';
import Layout4x4Image from '@images/landing-page/land-4x4.png';
import Layout8x8Image from '@images/landing-page/land-8x8.png';
import { useGlobalSetting } from '@/contexts/GlobalSettingContext';
import { ImageSlider } from './ImageSlider';
import { OwnershipDecorativeImages } from './OwnershipDecorativeImages';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './true-ownership-section.scss';
import { AnimatedHeader } from '../animated-header';

const images: string[] = [
	SkyTowerImage,
	Layout1x1Image,
	Layout2x2Image,
	Layout4x4Image,
	Layout8x8Image,
];

const largeScreenImages: string[] = [...images];
largeScreenImages.shift();

const TrueOwnershipSection: React.FC = () => {
	const { isSmallerScreen } = useGlobalSetting();

	return (
		<section
			className="ownership-section hexagon-bg"
			aria-labelledby="ownership-section-title"
		>
			<AnimatedHeader>
				<h2
					id="ownership-section-title"
					className="ownership-section__title"
				>
					<span className="ownership-section__title__line1">
						True ownership
					</span>
					<span className="ownership-section__title__line2">
						NFT | Virtual lands
					</span>
				</h2>
			</AnimatedHeader>
			<div className="ownership-section__description">
				<h3>True ownership</h3>
				<p>
					Create, Personalize and Trade lands and assets that express
					your true individuality in our virtual realm. All assets
					hold tangible value and can be traded freely on
					decentralized open markets. {isSmallerScreen && <><br /><br /></>}Through NFT empowerment, your
					assets are uniquely yours - within and outside of
					PLAYGROUND; now and forever.
				</p>
			</div>
			{isSmallerScreen ? (
				<ImageSlider images={images} />
			) : (
				<OwnershipDecorativeImages
					mainImage={SkyTowerImage}
					images={largeScreenImages}
				/>
			)}
		</section>
	);
};

export { TrueOwnershipSection };
