import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './true-immersion-section.scss';
import { AnimatedHeader } from '../animated-header';

const TrueImmersionSection: React.FC = () => {
	return (
		<section
			className="immersion-section"
			aria-labelledby="immersion-section-title"
		>
			<div
				className="immersion-section__main-image-wrapper"
				aria-hidden="true"
			>
				<StaticImage
					className="immersion-section__img"
					// imgClassName="immersion-section__decorative-full-avatar"
					aria-hidden="true"
					src={'../../assets/images/landing-page/phone.png'}
					loading="eager"
					placeholder="blurred"
					alt="full-phone"
				/>
				<StaticImage
					className="immersion-section__img immersion-section__decorative-avatar"
					// imgClassName="immersion-section__decorative-avatar"
					aria-hidden="true"
					src={'../../assets/images/landing-page/avatar-model.png'}
					loading="eager"
					placeholder="blurred"
					alt="full-avatar"
				/>
			</div>
			<div className="immersion-section__main-content">
				<div className="immersion-section__content">
					<AnimatedHeader>
						<h2
							id="immersion-section-title"
							className="immersion-section__title atmospheric-text"
						>
							True immersion
						</h2>
					</AnimatedHeader>
					<h3 className="immersion-section__subtitle atmospheric-text">
						Cutting edge graphics
					</h3>
					<p className="immersion-section__description corbel-text">
						Wander and get lost in a captivating world, replete with
						alluring landscapes, mesmerizing architectures and
						otherworldly skylines. Get soaked in cutting-edge
						visuals and graphical fidelity.
					</p>
				</div>
				<div
					className="immersion-section__image-wrapper"
					aria-hidden="true"
				>
						<div className="immersion-section__diamond-image">
							<div className="immersion-section__diamond-image-wrapper">
								<StaticImage
									className="immersion-section__diamond-image-content"
									aria-hidden="true"
									src="../../assets/images/landing-page/assassin-battle.png"
									loading="eager"
									placeholder="blurred"
									alt="assassin-battle"
								/>
							</div>
						</div>
						<div className="immersion-section__diamond-image">
							<div className="immersion-section__diamond-image-wrapper">
								<StaticImage
									className="immersion-section__diamond-image-content"
									aria-hidden="true"
									src="../../assets/images/landing-page/clock.png"
									loading="eager"
									placeholder="blurred"
									alt="clock"
								/>
							</div>
						</div>
						<div className="immersion-section__diamond-image">
							<div className="immersion-section__diamond-image-wrapper">
								<StaticImage
									className="immersion-section__diamond-image-content"
									aria-hidden="true"
									src="../../assets/images/landing-page/court.png"
									loading="eager"
									placeholder="blurred"
									alt="court"
								/>
							</div>
						</div>
				</div>
			</div>
		</section>
	);
};

export { TrueImmersionSection };
