import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './true-identity-section.scss';
import { AnimatedHeader } from '../animated-header';
import { LANDINGPAGE } from '@/constants/landing-page';
import { HexagonBGLayout } from '@/layouts/hexagon-bg-layout';

const title: string = 'True Identity. Be Yourself';

const TrueIdentitySection: React.FC = () => {
	const ref = React.useRef(null);
	return (
		<section
			className="identity-section"
			aria-labelledby="identity-section-title"
		>
			<StaticImage
				className="identity-section__decorative-full-avatar"
				aria-hidden="true"
				src={'../../assets/images/landing-page/half-human.png'}
				loading="eager"
				placeholder="blurred"
				alt="full-avatar"
			/>
			<AnimatedHeader>
				<h2
					id="identity-section-title"
					className="identity-section__title atmospheric-text"
				>
					<span
						className="identity-section__title__line1"
						data-content="True Identity."
					>
						True Identity.
					</span>
					<span
						className="identity-section__title__line2"
						data-content="Be Yourself."
					>
						Be Yourself.
					</span>
				</h2>
			</AnimatedHeader>
			<div className="identity-section__main-content">
				<HexagonBGLayout Tag="div" className="identity-section__description">
				{/* <div className="identity-section__description"> */}
					<h3 className="atmospheric-text">
						True <span>Identity</span>
					</h3>
					<div className="identity-section__content-wrapper">
						<div className="shape-contour" />
						<p
							className="corbel-text"
							dangerouslySetInnerHTML={{
								__html: LANDINGPAGE.trueIdentity.description,
							}}
						/>
					</div>
				{/* </div> */}
				</HexagonBGLayout>
			</div>
			<div
				className="identity-section__decorative-image-wrapper"
				aria-hidden="true"
			>
				<StaticImage
					aria-hidden="true"
					imgClassName="identity-section__neon"
					src="../../assets/images/neon-orange.png"
					loading="eager"
					placeholder="blurred"
					alt="neon"
				/>
				<div className="identity-section__avatar-list">
					<StaticImage
						className="identity-section__avatar-wrapper"
						aria-hidden="true"
						imgClassName="identity-section__avatar"
						src="../../assets/images/landing-page/circle-1.png"
						loading="eager"
						placeholder="blurred"
						alt="avatar"
					/>
					<StaticImage
						className="identity-section__avatar-wrapper"
						aria-hidden="true"
						imgClassName="identity-section__avatar"
						src="../../assets/images/landing-page/circle-2.png"
						loading="eager"
						placeholder="blurred"
						alt="avatar"
					/>
					<StaticImage
						className="identity-section__avatar-wrapper"
						aria-hidden="true"
						imgClassName="identity-section__avatar"
						src="../../assets/images/landing-page/circle-3.png"
						loading="eager"
						placeholder="blurred"
						alt="avatar"
					/>
				</div>
			</div>
		</section>
	);
};

export { TrueIdentitySection };
