import React from 'react';

export interface OwnershipDecorativeImagesProps {
  mainImage: string;
  images: string[];
}

const OwnershipDecorativeImages: React.FC<OwnershipDecorativeImagesProps> = ({ mainImage, images }: OwnershipDecorativeImagesProps) => {
	return (
		<>
			<div
				className="ownership-section__layout-images-wrapper"
				aria-hidden="true"
			>
        {
          images.map((item: string, index: number) => {
            return <img
              className="ownership-section__layout-image"
              src={item}
              alt={`image ${index}`}
              key={index}
            />
          })
        }
			</div>
			<img
				className="ownership-section__decorative-image"
				src={mainImage}
				alt="skymap"
				aria-hidden="true"
			/>
		</>
	);
};

export { OwnershipDecorativeImages };
